import { ROUTE_NAMES } from "@/utils/routes.const";
import CompanyLayout from "@/company/layouts/CompanyLayout.vue";
import path from "path";

/**
 * CompanyRoutes is an array of route records which are used to define the
 * company-related routes in a web application. Each route record
 * adheres to the RouteRecordRaw interface, a structure that defines
 * the properties and configuration for each route.
 *
 * Example route configurations may include paths, names, components,
 * redirection targets, guards, and other route-specific metadata.
 *
 * Typically used within a routing library such as Vue Router in a
 * Vue.js application, these routes are essential for managing company
 * processes like dashboard, job postings, candidates, etc.
 *
 * @name CompanyRoutes
 */
export const CompanyRoutes = [
  {
    path: "/company",
    component: CompanyLayout,
    children: [
      {
        name: ROUTE_NAMES.COMPANY.DASHBOARD,
        path: "",
        component: () => import("@/company/pages/Dashboard/DashboardIndex.vue"),
        meta: { auth: true, title: "Dashboard" },
      },
      {
        path: "jobs",
        component: () => import("@/company/pages/Jobs/Jobs.vue"),
        children: [
          {
            name: ROUTE_NAMES.COMPANY.JOBS,
            path: "",
            component: () => import("@/company/pages/Jobs/JobIndex.vue"),
            meta: { auth: true, title: "Jobs" },
          },
          {
            name: ROUTE_NAMES.COMPANY.JOB_DETAILS,
            path: ":job_hashid",
            component: () =>
              import("@/company/pages/Jobs/Components/JobDetails.vue"),
            meta: { auth: true, title: "Job Details" },
            props: true,
          },
          {
            name: ROUTE_NAMES.COMPANY.CANDIDATES,
            path: ":job_hashid/candidates",
            component: () =>
              import("@/company/pages/Others/ManageCandidates.vue"),
            meta: { auth: true, title: "Candidates" },
            props: true,
          },
          {
            name: ROUTE_NAMES.COMPANY.CANDIDATE,
            path: ":job_hashid/candidates/:candidate_id",
            component: () =>
              import("@/company/pages/Others/CandidateView.vue"),
            meta: { auth: true, title: "Candidate" },
            props: true,
          },
        ],
      },
      {
        name: ROUTE_NAMES.COMPANY.CREATE_JOB,
        path: "jobs/create",
        component: () => import("@/company/pages/Jobs/Create.vue"),
        meta: { auth: true, title: "Create Job" },
        children: [
          {
            name: ROUTE_NAMES.COMPANY.CREATE_JOB_STEP2,
            path: "step2",
            component: () =>
              import("@/company/pages/Jobs/Components/CreateJob/Step2.vue"),
            meta: { auth: true, title: "Create Job Step 2" },
          },
        ],
      },
      {
        name: ROUTE_NAMES.COMPANY.PROFILE,
        path: "profile",
        component: () => import("@/company/pages/Profile/ProfileIndex.vue"),
        meta: { auth: true, title: "Profile" },
      },
      {
        name: ROUTE_NAMES.COMPANY.SETTINGS,
        path: "settings",
        component: () => import("@/company/pages/Settings/SettingIndex.vue"),
        meta: { auth: true, title: "Settings" },
        redirect: { name: ROUTE_NAMES.COMPANY.SETTINGS_GENERAL },
        children: [
          {
            path: "general",
            name: ROUTE_NAMES.COMPANY.SETTINGS_GENERAL,
            component: () =>
              import(
                "@/company/pages/Settings/Components/Basics/GeneralSettings.vue"
              ),
          },
          {
            path: "basic-details",
            name: ROUTE_NAMES.COMPANY.SETTINGS_BASIC_DETAILS,
            component: () =>
              import(
                "@/company/pages/Settings/Components/Basics/BasicDetails.vue"
              ),
          },
          {
            path: "email",
            name: ROUTE_NAMES.COMPANY.SETTINGS_EMAIL,
            component: () =>
              import(
                "@/company/pages/Settings/Components/Basics/EmailSettings.vue"
              ),
          },
          {
            path: "password",
            name: ROUTE_NAMES.COMPANY.SETTINGS_PASSWORD,
            component: () =>
              import(
                "@/company/pages/Settings/Components/Security/ChangePassword.vue"
              ),
          },
          {
            path: "2fa",
            name: ROUTE_NAMES.COMPANY.SETTINGS_2FA,
            component: () =>
              import(
                "@/company/pages/Settings/Components/Security/TwoFactorSettings.vue"
              ),
          },
          {
            path: "slack",
            name: ROUTE_NAMES.COMPANY.SETTINGS_SLACK,
            component: () =>
              import(
                "@/company/pages/Settings/Components/Security/SlackSettings.vue"
              ),
          },
          {
            path: "notification",
            name: ROUTE_NAMES.COMPANY.SETTINGS_NOTIFICATIONS,
            component: () =>
              import(
                "@/company/pages/Settings/Components/NotificationSettings.vue"
              ),
          },
          {
            path: "roles",
            name: ROUTE_NAMES.COMPANY.SETTINGS_ROLES,
            component: () =>
              import("@/company/pages/Settings/Components/RolesSettings.vue"),
          },
        ],
      },
      {
        name: ROUTE_NAMES.COMPANY.NOTIFICATIONS,
        path: "notifications",
        component: () =>
          import("@/company/pages/Notification/NotificationIndex.vue"),
        meta: { auth: true, title: "Notifications" },
      },
    ],
  },
];
