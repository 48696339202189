export const ROUTE_NAMES = {
  HOME: "home",
  AUTH: {
    LOGIN: "auth:login",
    SIGNUP: "auth:signup",
    FORGOT_PASSWORD: "auth:forgot-password",
  },
  COMPANY: {
    DASHBOARD: "company:dashboard",
    JOBS: "company:jobs",
    JOB_DETAILS: "company:jobs:job_hashid",
    CANDIDATE: "company:jobs:job_hashid:candidates:candidate_id",
    CANDIDATES: "company:jobs:job_hashid:candidates",
    CREATE_JOB: "company:create-job",
    GENERATE_PROMPT: "company:generate-prompt",
    PROFILE: "company:profile",
    NOTIFICATIONS: "company:notifications",
    CREATE_JOB_STEP2: "company:create-job:step2",
    SETTINGS_GENERAL: "company:settings-general",
    SETTINGS_BASIC_DETAILS: "company:settings-basic-details",
    SETTINGS_EMAIL: "company:settings-email",
    SETTINGS_PASSWORD: "company:settings-password",
    SETTINGS_SLACK: "company:settings-slack",
    SETTINGS_NOTIFICATIONS: "company:settings-notifications",
    SETTINGS_ROLES: "company:settings-roles",
  },
  CONSULTANT: {
    DASHBOARD: "consultant:dashboard",
    JOBS: "consultant:jobs",
    JOB_DETAILS: "consultant:jobs:job_hashid",
    CANDIDATE: "consultant:jobs:job_hashid:candidates:candidate_id",
    CANDIDATES: "consultant:jobs:job_hashid:candidates",
    COMPANIES: "consultant:company",
    COMPANY_DETAILS: "consultant:company:company_hashid",
    COMPANY_JOBS: "consultant:company:company_hashid:jobs",
    COMPANY_JOB: "consultant:company:company_hashid:jobs:job_hashid",
    CREATE_JOB: "consultant:create-job",
    GENERATE_PROMPT: "consultant:generate-prompt",
    PROFILE: "consultant:profile",
    NOTIFICATIONS: "consultant:notifications",
    SETTINGS_GENERAL: "consultant:settings-general",
    SETTINGS_BASIC_DETAILS: "consultant:settings-basic-details",
    SETTINGS_EMAIL: "consultant:settings-email",
    SETTINGS_PASSWORD: "consultant:settings-password",
    SETTINGS_SLACK: "consultant:settings-slack",
    SETTINGS_NOTIFICATIONS: "consultant:settings-notifications",
    SETTINGS_ROLES: "consultant:settings-roles",
  },
  CANDIDATE: {
    DASHBOARD: "candidate:dashboard",
    JOBS: "candidate:jobs",
    SETTINGS: "candidate:settings",
    PROFILE: "candidate:profile",
    NOTIFICATIONS: "candidate:notifications",
    SETTINGS_GENERAL: "candidate:settings-general",
    SETTINGS_BASIC_DETAILS: "candidate:settings-basic-details",
    SETTINGS_EMAIL: "candidate:settings-email",
    SETTINGS_PASSWORD: "candidate:settings-password",
    SETTINGS_SLACK: "candidate:settings-slack",
    SETTINGS_NOTIFICATIONS: "candidate:settings-notifications",
    SETTINGS_ROLES: "candidate:settings-roles",
    WORK_HISTORY: "candidate:work-history",
  },
};
