import { ROUTE_NAMES } from "@/utils/routes.const";

/**
 * AuthRoutes is an array of route records which are used to define the
 * authentication-related routes in a web application. Each route record
 * adheres to the RouteRecordRaw interface, a structure that defines
 * the properties and configuration for each route.
 *
 * Example route configurations may include paths, names, components,
 * redirection targets, guards, and other route-specific metadata.
 *
 * Typically used within a routing library such as Vue Router in a
 * Vue.js application, these routes are essential for managing user
 * authentication processes like login, signup, password recovery, etc.
 *
 * @name AuthRoutes
 */
export const AuthRoutes = [
    {
        name: ROUTE_NAMES.AUTH.LOGIN,
        path: '',
        component: () => import('@/auth/pages/LoginPage.vue'),
        meta: { auth: false, title: 'Login' }
    },
    {
        name: ROUTE_NAMES.AUTH.SIGNUP,
        path: '/signup',
        component: () => import('@/auth/pages/SignUpPage.vue'),
        meta: { auth: false, title: 'Sign Up' }
    },
];